import { computed } from 'vue'
import { PERMISSIONS } from '~/config/permissions'
import type { PermissionSet } from '~/config/permissions'
import type { RoleKey } from '~/types/roleKey'

type UseAuth = PermissionSet

export default function useAuth(): UseAuth {
  const $store = useStore()
  const canEnqDownload = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canEnqDownload ?? false
  })

  const canMemberAdd = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canMemberAdd ?? false
  })

  const canMemberEdit = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canMemberEdit ?? false
  })

  const canMemberDelete = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canMemberDelete ?? false
  })

  const canMessageSend = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canMessageSend ?? false
  })

  const canCsvAdd = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canCsvAdd ?? false
  })

  const canSeniorAdd = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canSeniorAdd ?? false
  })

  const canMailSetting = computed(() => {
    if (!$store.getUser?.roles) return false
    const userRole = $store.getUser?.roles[0].value as RoleKey
    return PERMISSIONS[userRole]?.canMailSetting ?? false
  })

  return {
    canEnqDownload: canEnqDownload.value,
    canMemberAdd: canMemberAdd.value,
    canMemberEdit: canMemberEdit.value,
    canMemberDelete: canMemberDelete.value,
    canMessageSend: canMessageSend.value,
    canCsvAdd: canCsvAdd.value,
    canSeniorAdd: canSeniorAdd.value,
    canMailSetting: canMailSetting.value,
  }
}
