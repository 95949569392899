import type { RoleKey } from '~/types/roleKey'

export type PermissionSet = {
  canEnqDownload: boolean // アンケート結果のダウンロード権限
  canMemberAdd: boolean // メンバー登録権限
  canMemberEdit: boolean // メンバー情報（基本情報／詳細情報／アンケート設定）編集権限
  canMemberDelete: boolean // メンバーの削除権限
  canMessageSend: boolean // メッセージ送信権限
  canCsvAdd: boolean // メンバー一括追加権限
  canSeniorAdd: boolean // 上司登録権限
  canMailSetting: boolean // 自動通知メール設定権限
}

type Permissions = {
  [key in RoleKey]: PermissionSet
}

// 権限によるコンポーネント制御を定義
export const PERMISSIONS: Permissions = {
  owner: {
    canEnqDownload: true,
    canMemberAdd: true,
    canMemberEdit: true,
    canMemberDelete: true,
    canMessageSend: true,
    canCsvAdd: true,
    canSeniorAdd: true,
    canMailSetting: true,
  },
  super_administrator: {
    canEnqDownload: true,
    canMemberAdd: true,
    canMemberEdit: true,
    canMemberDelete: true,
    canMessageSend: true,
    canCsvAdd: true,
    canSeniorAdd: false,
    canMailSetting: false,
  },
  limited_administrator: {
    canEnqDownload: true,
    canMemberAdd: false,
    canMemberEdit: true,
    canMemberDelete: false,
    canMessageSend: true,
    canCsvAdd: false,
    canSeniorAdd: false,
    canMailSetting: false,
  },
  standard_viewer: {
    canEnqDownload: true,
    canMemberAdd: false,
    canMemberEdit: false,
    canMemberDelete: false,
    canMessageSend: false,
    canCsvAdd: false,
    canSeniorAdd: false,
    canMailSetting: false,
  },
  restricted_viewer: {
    canEnqDownload: false,
    canMemberAdd: false,
    canMemberEdit: false,
    canMemberDelete: false,
    canMessageSend: false,
    canCsvAdd: false,
    canSeniorAdd: false,
    canMailSetting: false,
  },
}
